.failure_main {
    background-image: url(../../../assets/images/thankubackgrnd.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 0px;
  }
  
  .booking_failed {
    width: 100%;
    font-weight: 800;
    color: rgba(255, 115, 115, 1);
    font-size: 30px;
    font-family: sans-serif;
    padding: 5px 5px 5px 5px;
    text-align: center;
  }
  
  .failed_reason {
    color: rgba(0, 155, 214, 1);
    width: 100%;
    padding: 5px 5px 14px px 5px;
    text-align: center;
    font-family: sans-serif;
    font-weight: 600;
  }
  
  .go_home_btn {
    border: 1px solid rgba(0, 0, 0, 1);
    width: 20%;
    border-radius: 4px;
    padding: 11px;
    color: rgba(255, 133, 133, 1);
    background: white;
    font-weight: 600;
    font-family: sans-serif;
  }
  
  .btn_align {
    margin: 50px;
    text-align: center;
  }
  
  @media (max-width: 767px) {
    .go_home_btn {
      width: 70%;
    }
  }
  