@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.view_room {
  background: #ffffff;
  border: 1px solid #cee5ff;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 108, 207, 0.1);
  color: #03438d;
  font-size: 11px;
  /* font-family: "Inter", sans-serif; */  
  font-style: normal;
  font-weight: 500;
  height: 35px;
  line-height: 13px;
  margin: 0px 5px;
  width: 15%;
}

/* .main_increment input{
    pointer-events: none;
} */
.main_view_room.flt {
  text-align: end;
}

.table_container {
  margin: 10px 0px;
}

/* table {
  border-collapse: collapse;
  width: 80%;
  
}

th,
td {
  border: 1px solid #d49e2c;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #d49e2c;
  color: white;
  font-weight: bold;
}
tr:nth-child(even) {
  background-color: #c0b0b0;
}

tr:nth-child(odd) {
  background-color: #f2f2f2;
} */

.table {
  border-collapse: collapse;
  margin: auto;
  text-align: center;
}

.table-head {
  background-color: #d49e2c;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.table-head tr th{
  color: white;
  font-family: 'Roboto', sans-serif;
}

.table-row:nth-child(even) {
  background-color: #cfcfcf;
  font-size: 16px;
}

.table-row:nth-child(odd) {
  background-color: #f2f2f2;
  font-size: 16px;
}

.table-header,
.table-data {
  padding: 8px;
}

.table-header {
  background-color: #d49e2c !important;
}

.table-data {
  font-weight: normal;
}

.view_room img {
  padding: 5px;
}

.room_stnd.flt {
  color: #002c60;
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.01px;
  line-height: 32px;
  padding: 15px 12px 5px;
}

.points{
  color: rgb(99, 99, 99);
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  list-style-position: inside;
  list-style-type: square;
  margin-top: 10px !important;
}

.rooms_left{
    background: #a1a1a11c;
    color: rgb(216, 0, 0);
  display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.5px;
    padding: 3px 7px;
    width: auto;
}


.main_standerd_rooom.flt {
  background: #ffffff;
  border-radius: 2px;
  box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
  margin: 10px 0px;
}
.main_Standerd_room {
  border-radius: 2px;
  border-radius: 2px;
  box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
  margin: 10px 0px;
}
.view_white {
  display: none;
}

.view_room:hover .view_blue {
  display: none !important;
}

.view_room:hover .view_white {
  display: inline-block !important;
}

.view_room:hover {
  background-color: #03438d;
  color: #fff;
}

.right_view_room img {
  width: 100%;
  /* padding: 5px 5px; */
  /* height: 400px; */
  /* object-fit: cover; */
}

.main_view.flt {
  display: flex;
  padding: 10px 10px;
}

.right_view_room {
  /* display: flex;
     */
  width: 85%;
}

.left_view_room {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
  width: 15%;
  gap: 5px;
}

.left_view_room li {
  list-style-type: none;
  position: relative;
}

/* .left_view_room img {
    padding: 0px 0px 5px 5px !important;
   
} */

.left_view_room li:last-child {
  padding-bottom: 0px !important;
}

.left_view_room li:last-child span {
  bottom: 50%;
  color: #ffffff !important;
  display: block;
  font-size: 20px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  position: relative;
  text-align: center;
}

.left_view_room li img {
  cursor: pointer;
  width: 100%;
  /* height: 77px; */
}

.left_view_room .selected_img::after {
 background-color: #d49e2c;
 content: "";
 height: 100%;
 position: absolute;
 width: 3px;
}

element.style {
  text-decoration: none;
}

.last_img {
  position: relative;
}

.last_img span {
  color: #ffffff !important;
  font-size: 20px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  position: absolute;
  right: 25%;
  top: 33%;
}

.room_description.flt {
  color: #2d3436;
  font-family: 'Roboto', sans-serif;
  font-size: 19px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  padding: 7px 0px;
}

.carousel .thumbs-wrapper {
  margin: 0px !important;
}

.modal-header {
  justify-content: flex-end !important;
  padding: 0px 20px !important;
}

.description {
  color: #2d3436;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  padding: 10px 0px;
  text-align: justify;
  word-break: break-word;
}

.main_leftside.flt {
  padding: 30px 20px;
}

.main_row_pad.flt {
  padding: 20px 0px;
}

.checkin.flt {
  align-items: center;
  display: flex;
  margin-bottom: 22px;
}

.checkout {
  color: #002c60;
  font-size: 18px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  padding: 0px 10px;
  font-family: 'Roboto', sans-serif;
}

.main_checkin.flt {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
  padding: 30px 15px 20px 15px;
  margin-top: 10px;
}

.check_aprl {
  color: #ecb200;
  font-size: 12px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
}

/* .main_cla {
    padding-top: 20px;
} */

/* .main_cal_image.flt {

    padding: 20px 0px;
} */

.main_check ul {
  margin: 0px;
  padding: 0px;
}

.main_check li {
  align-items: center;
  display: flex;
  list-style-type: none;
}

.check_box label {
  color: #000000;
  font-size: 12px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  padding-left: 8px;
  text-transform: capitalize;
}

.check_box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.main_services.flt {
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgb(0 108 207 / 20%);
  padding: 22px 15px;
}

.extra_ser.flt {
  padding: 10px 0px;
}

.services {
  color: #000000;
  font-size: 17px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  margin-bottom: 20px;
}

.parking_money {
  color: #000000;
  font-size: 14px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  text-align: right;
}

.main_det.flt {
  padding-top: 10px;
}

.main_tnum {
  color: #ffab29;
  font-size: 41.9703px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 200;
  line-height: 51px;
}

.main_cal_image.flt {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  position: relative;
}

/* .main_che_pad {
    padding: 5px;
    border-radius: 2px;
} */

.guest_name {
  color: #a00028;
  font-size: 12px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
}

.guest {
  background: #ffdede;
  border-radius: 2px;
  padding: 15px;
}

.main_input input {
  background: none;
  border: none;
  color: #a00028;
  font-size: 24px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 200;
  outline: none;
  text-align: center;
  width: 100%;
}

.guest_img {
  align-items: center;
  display: flex;
}

.main_input {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.main_adults span {
  color: #a00028;
  font-size: 12px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  padding: 0px 5px;
}

.main_adult span {
  color: #a00028;
  font-size: 12px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  padding: 0px 5px;
}

.guest1 {
  background: #eccdc2;
  border-radius: 2px;
  padding: 19px;
}

.guest_name1 {
  color: #5d3a30;
  font-size: 12px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
}

.main_input {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.main_input1 input {
  background: none;
  border: none;
  color: #5d3a30;
  font-size: 41.9703px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 200;
  line-height: 51px;
  outline: none;
  text-align: center;
  width: 100%;
}

.total_length {
  color: #2d3436;
  font-size: 15px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  margin-top: 15px;
}
.child_pax {
  color: #2d3436;
  font-size: 15px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin: 15px 0px;
}
.child_paxs {
  color: #2d3436;
  font-size: 15px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  /* margin-left: 45px; */
  /* margin-inline-start: 120px; */
  line-height: 20px;
  margin-top: 15px;
  margin-top: 115px;
}
hr.line {
  background: #121212 !important;
  height: 1px !important;
  margin: 6px !important;
  opacity: none !important;
}

hr.line1 {
  background: #121212 !important;
  height: 1px !important;
  margin: 6px -5px !important;
}

ul.room_charge {
  margin: 0px;
  padding: 0px 10px !important;
  padding: 0px;
}

.room_charge li {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 8px 0px;
}

.charges2 {
  color: #2d3436;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
}
.charges_bold {
  font-weight: 600;
}
.total_amt.flt {
  margin: 0px;
  padding: 0px 10px !important;
  padding: 0px;
}

.total_amt li {
  align-items: center;
  display: flex;
  justify-content: space-between;
  list-style-type: none;
  padding: 10px 0px;
}

.ammount_clr {
  color: #053469;
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}
.ammount_clr_no {
  color: #053469;
  font-size: 17px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}

.main_check {
  padding: 0px 10px;
}

.main_continue {
  background: #d49e2c;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0px 11.6571px rgb(0 108 207 / 10%);
  color: rgb(255, 255, 255);
  font-size: 13px;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  padding: 15px 0px;
}
/* 
.main_continue:hover {
  background: #ffffff;
  color: #0a8349;
} */

.continue_btn.flt {
  margin-top: 18px;
}

.left_view_room li {
  list-style-type: none;
  padding: 0px 0px 0px 5px;
}

.react-datepicker-wrapper input {
  background: none;
  border: none;
  color: #ffab29;
  font-size: 20px;
  outline: none;
  padding-left: 10px;
  width: 100%;
}

.modal-content {
  position: absolute !important;
}

/* .main_row{
    position: relative !important;
} */
.carousel .carousel-status {
  visibility: hidden;
}

.carousel.carousel-slider .control-arrow {
  visibility: hidden;
}

.modalPop {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out;
  width: 100%;
  z-index: 1050;
}

ul.left_view_room li span {
  display: none;
}

.form-control {
  border: none !important;
  /* background-color:transparent !important; */
  color: #ecb200 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  outline: none !important;
  padding: 8px 1px !important;
  text-align: center;
}
.rdt .form-control {
  /* color: rgb(0, 0, 0) !important;
  font-weight: 500 !important; */
  color: #434343 !important;
  font-weight: 400 !important;
  padding: 15px 5px 15px 35px !important;
  text-align: left;
}
.rdt .form-control::placeholder {
  color: rgb(0, 0, 0) !important;
}

.rdtOpen .rdtPicker {
  display: block;
  left: -50px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  background-color: none;
  opacity: 1;
}

.form-control[readonly] {
  background-color: #ffffff !important;
  opacity: 1;
}

.rdt {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  position: relative;
}

/* 
.main_imgcal {
    position: relative;
    z-index: 1;
    left: 20%;
    top: 0;
} */

.person_count {
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  margin: 0px 10px;
}

.main_adult_img {
  align-items: center;
  display: flex;
  width: 50%;
}

.main_increment {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}
.main_increment select {
  width: 100%;
}

.main_adult_img {
  align-items: center;
  display: flex;
}

.decrement_button {
  background: #053469;
  border: 1px solid #053469;
  border-radius: 4px;
  color: #fff;
  display: flex;
  justify-content: center;
  width: 18%;
}

.increment_button {
  background: #053469;
  border: 1px solid #053469;
  border-radius: 4px;
  color: #fff;
  display: flex;
  justify-content: center;
  /* width: 28px;
height: 28px;  */
  width: 18%;
}

.main_increment input {
  color: #053469;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  text-align: center;
}

.guest_adults.flt {
  align-items: center;
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  margin: 10px 0px;
  padding: 10px;
}
.main_guest_adults .guest_adults:last-child {
  margin-bottom: 20px;
}
.main_guest_adults .guest_adults:first-child {
  margin-top: 20px;
}
.decrement_button i {
  font-size: 14px;
  padding: 5px;
}

.increment_button i {
  font-size: 14px;
  padding: 5px;
}

.main_cal_image img {
  bottom: 0px;
  left: 10px;
  position: absolute;
  top: 16px;
  z-index: 1;
}

.react-datepicker-wrapper input {
  background: none;
  border: none;
  color: #ffab29;
  font-size: 20px;
  outline: none;
  padding-left: 10px;
  width: 100%;
}

.modal-content {
  position: absolute !important;
}


.carousel .carousel-status {
  visibility: hidden;
}

.carousel.carousel-slider .control-arrow {
  visibility: hidden;
}

.modalPop {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100vh;
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out;
  width: 100%;
  z-index: 1050;
}

ul.left_view_room li span {
  display: none;
}

.form-control {
  border: none !important;
  color: #ecb200 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  outline: none !important;
  padding: 8px 1px !important;
}

#shippingModal .modal-dialog {
  margin: 1.75rem auto;
  max-width: 500px;
}

#shippingModal .modal-dialog .modal-content {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  width: 100%;
}

#shippingModal .modal-body {
  padding: 1rem;
}

#shippingModal .modal-header {
  border-bottom: 1px solid #dee2e6;
  justify-content: flex-end !important;
  padding: 0px 20px !important;
}
.text_right {
  text-align: right;
}

.room_rate span {
  color: #434343;
  font-weight: 400;
}

.standard_amenities li {
  float: left;
  list-style-type: none;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
  width: 33.33%;
}
.standard_amenities img {
  margin-right: 10px;
}
.standard_amenities span {
  color: #2d3436;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}
.main_increment select {
  background-color: transparent;
  border: 1px solid #ced4da;
  color: #434343;
  font-size: 13px;
  font-weight: 400;
  outline: none;
  padding: 5px;
}
.guest_pad .room_rate {
  color: #002c60;
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 37px;
}
.guest_pad .room_rate span {
  font-size: 20px;
  font-weight: 400;
}
.main_row_container{
  min-height: calc(100vh - 150px);
}
.main_row > .main_hotel{
  padding: 30px 0;
}

@media (min-width: 640px) and (max-width: 767px) {
  .main_row .col-sm-8,
  .main_row .col-sm-4 {
    width: 100%;
  }
  .main_wifi1_det li {
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .main_row .col-sm-8,
  .main_row .col-sm-4 {
    width: 100%;
  }
  .main_wifi1_det li {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .main_discount {
    margin: 15px 0px;
  }
  .right_view_room {
    float: left;
    width: 100%;
  }

  .right_view_room img {
    height: auto;
    width: 100%;
  }

  .left_view_room {
    justify-content: space-around;
    width: 100%;
  }
  .main_leftside {
    padding: 0px 15px !important;
  }
  .main_row_pad {
    padding: 0px 15px !important;
  }
  .rdtOpen .rdtPicker {
    display: block;
    left: -1px;
  }
  .left_view_room .selected_img::after {
    display: none;
   }
 
}
.rdtDays tfoot {
  visibility: hidden;
}
/* 
.addroom_btn {
  margin-top: 10px;
} */
.addroom_btn button {
  align-items: center;
  background: #d49e2c;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 0px 11.6571px rgb(0 108 207 / 10%);
  color: rgb(255, 255, 255);
  display: flex;
  font-size: 14px;
  gap: 5px;
  padding: 8px 16px;
}

.addroom_btn button span{
  font-size: 23px;
  line-height: 0px;
}

.room_remove {
  background-color: #d49e2c;
  border: none;
  border-radius: 4px;
  color: rgb(255, 255, 255);
  font-size: 15px;
  margin-left: 5px;
  padding: 5px 13px;
}
.main_guest_adults {
  background-color: #d49e2c54;
  border-radius: 2px;
  margin-top: 15px;
  padding: 0px 15px;
}
.addroom_block {
  margin-top: 15px;
}
.guest_flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.opacityadd {
  opacity: 0.5;
  pointer-events: none;
}

@media (min-width: 768px) {
  .standard_amenities {
    padding: 0px 12px !important;
  }
  .main_description {
    padding: 0px 12px;
  }
}

