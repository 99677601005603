/* TopBar start */

.Agile_text {
  font-family: "K2D";
  font-style: normal;
  font-weight: 700;
  font-size: 13.38px;
  line-height: 17px;
  letter-spacing: 0.49em;
  text-transform: uppercase;
  color: #000000;
}

.hotel_book {
  font-family: "K2D";
  font-style: normal;
  font-weight: 400;
  font-size: 7px;
  line-height: 8px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #000000;
}

.agile {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 108, 207, 0.2);
  display: flex;
  align-content: center;
  padding: 20px 30px;
}

.footer {
  margin-top: 15px;
  font-family: "Open Sans";
  background: #ffffff;
  box-shadow: 0px -4px 20px rgba(0, 108, 207, 0.2);
  display: flex;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 10px 30px;
}

.main_location.flt {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.loction_image img {
  padding: 3px;
  border: 1px solid #b9b6ec;
  border-radius: 3px;
  background: #f5faff;
  margin-right: 10px;
}

.loction_image select {
  border: none;
  outline: none;
  background: none;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: #242040;
}

.search_bar.flt {
  width: 100%;
}

.loction_image {
  background: #f5faff;
  border: 1px solid #002856;
  border-radius: 4px;
  padding: 5px;
  margin: 0px 10px;
}

input[type="checkbox"]::before {
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.main_agile_image {
  cursor: pointer;
}

.main_agile_image .main_logo{
  width: 90px;
}

.search {
  width: 90%;
  position: relative;
  display: flex;
  padding: 0px 8px;
}

.searchTerm {
  width: 100%;
  background: #f5faff;
  border: 1px solid #ffb94e;
  border-radius: 4px;
  height: 40px;
  outline: none;
  color: #9dbfaf;
}

.searchTerm:focus {
  color: #00b4cc;
}

.searchButton {
  position: absolute;
  background: #f5faff;
  border: 1px solid #ffd18a;
  border-radius: 3px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  margin: 2px;
  top: 5%;
  left: 85%;
  padding: 0px;
}

.search img {
  padding: 5px;
}

.main_login {
  display: flex;
  align-items: center;
}

.main_login {
  display: flex;
  align-items: center;
}

.user_login {
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 0px 4px rgb(5 52 105 / 10%);
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  padding: 10px;
}

.log_in {
  /* 
    font-family: 'Raleway'; */
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  padding: 0px 20px;
  color: #2d3436;
}

.choose_room.flt {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #002c60;
  padding: 0px 30px 25px 30px;
}

.main_row {
  padding: 0px 30px;
}

.room_image.flt {
  text-align: center;
}

.main_standerd.flt {
  text-transform: capitalize;
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #2d3436;
  text-align: left;
  padding: 20px 0px 15px 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bed_name.flt {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
}

.king_bed {
  display: flex;
  align-items: center;
}

.main_king_bed {
  display: flex;
  align-items: center;
  padding: 15px 0px;
  justify-content: space-between;
}

.main_room_image.flt {
  margin-bottom: 20px;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 108, 207, 0.2);
  border-radius: 2px;
}

.king_bed img {
  padding: 0px 2px;
}

.room_image img {
  width: 100%;
  height: 200px;
}

.wifi {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #545454;
  padding: 0px 7px;
}

.main_wifi {
  display: flex;
  align-items: center;
  width: 33%;
}

.main_television {
  display: flex;
  align-items: center;
  width: 33%;
}

.main_shower {
  display: flex;
  align-items: center;
  width: 33%;
}

.Main_wifi_det.flt {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
}

.rate {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  text-decoration-line: line-through;
  color: #ff8888;
}

.discount.flt {
  padding: 5px 0px;
}

.room_rate {
  /* font-family: "Inter", sans-serif; */
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 18px;
  line-height: 24px;
  color: #002c60;
  text-align: right;
}
.room_rates {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 26px;
  line-height: 37px;
  color: #002c60;
}

.room_per {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #282828;
}

.main_room_per.flt {
  padding: 8px 0px;
}

.tax_fees {
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.select_room {
  background: #d49e2c;
  box-shadow: 0px 0px 11.6571px rgb(0 108 207 / 10%);
  border: none;
  color: rgb(255, 255, 255);
  /* font-family: "Inter", sans-serif; */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  border-radius: 5px;
  padding: 12px 25px;
}

/* .select_room:hover {
        color: #0A8349;
    background: #ffffff;
    border: 1px solid  #0A8349;
    box-shadow: 0px 0px 10px rgba(0, 108, 207, 0.1);
} */

.myaccount {
  position: relative;
}

.myaccount::before {
  content: " ";
  position: absolute;
  top: -12px;
  left: -25px;
  background-color: rgb(78 72 67 / 45%);
  width: 1px;
  height: 55px;
}

.myaccount:hover ul {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  transition: all 0.5s ease-in-out;
  opacity: 1 !important;
  visibility: visible !important;
  cursor: pointer;
}

.myaccount button {
  /* background: #F5FAFF;
    padding: 0px; */
  text-align: left;
  font-size: 14px;
  /* border: none; */
  outline: none;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 0px 4px rgba(5, 52, 105, 0.1);
  border-radius: 4px;
  background-color: transparent;
  padding: 4px 6px;
}

/* 
.myaccount button img {
    border: 1px solid #053469;
    padding: 3px;
    border-radius: 50%;
} */

.myaccount button i {
  margin: 0px 5px;
}

.myaccount ul {
  margin: 0px;
  padding: 8px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 4px rgb(120 120 120 / 50%);
  border-top: 2px solid #d49e2c;
  border-radius: 4px;
  width: 140px;
  position: absolute;
  right: 0px;
  top: 35px;
  z-index: 9;
  -webkit-transform: translateY(70px);
  transform: translateY(70px);
  /* opacity: 0; */
  visibility: hidden;
  transition: 0.5s ease-in-out;
  /* z-index: 1; */
}

.myaccount li {
  list-style-type: none;
  background-color: #d49e2c54;
  padding: 5px 10px;
  font-size: 13px;
  position: relative;
  cursor: pointer;
}

.myaccount li:first-child {
  margin-bottom: 5px;
}

.myaccount li i {
  font-weight: 600;
  color: #2d3436;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}

.myaccount li span {
  color: #2d3436;
}

.myaccount li img {
  margin-right: 3px;
}

/* TopBar end */

@media (max-width: 767px) {
  .home_row {
    padding: 0px !important;
  }

  .footer {
    padding: 15px;
    font-size: 11px;
  }
  .main_agile_image .main_logo{
    width: 60px;
  }
}
