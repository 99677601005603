/* tooltip */
.tooltip_container{
    cursor: pointer;
    position: relative;
    display: inline-block;
  }
  
  .tooltip_container svg{
    opacity: 0.8;
    font-size: 15px !important;
    margin-bottom: 1px;
  }
  
  .tooltip_container svg:hover{
    opacity: 0.6;
  }
  
  .tooltip_inner{
    padding: 6px;
    position: absolute;
    background-color: rgb(255, 255, 255);
    min-width: 180px;
    height: 60px;
    left: calc(-90px + 7px);
    bottom:23px;
    border-radius: 5px;
    border: 1px solid #d49e2c;
    box-shadow: 1px 0px 9px 1px rgba(176,176,176,0.22);
    -webkit-box-shadow: 1px 0px 9px 1px rgba(176,176,176,0.22);
    -moz-box-shadow: 1px 0px 9px 1px rgba(176,176,176,0.22);
    align-items: center;
    display: none;
  }
  
  .tooltip_container:hover .tooltip_inner{
    display: flex;
    animation: fade 0.7s  ease-out ;
    }
    .tooltip_table{
        width: 100%;
    }
  
    .tooltip_table td{
        font-size: 12px;
        padding: 3px 0;
    }
    @keyframes fade {
      from {bottom: 10px; opacity: 0.1;}
      to{bottom: 23px; opacity: 1;}
    }

    @media (max-width : 992px) {
        .tooltip_inner{
            left: -30px;
          }
    }
